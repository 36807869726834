<template>
  <div class="position-relative bottom-carousel">
    <img src="@/assets/img/logo.png" class="bottom-carousel__logo">
    <splide :slides="list" :options="options" ref="bottomCarousel">
      <splide-slide v-for="(item, idx) in list" :key="idx" class="d-flex">
        <div
          v-for="img in item"
          :key="img"
          class="slide-img"
          :class="{'slide-img__multiple': item.length > 1}"
          :style="{backgroundImage: `url(${img})`}"
        ></div>
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'BottomCarousel',
  components: {
    Splide,
    SplideSlide
  },
  data () {
    return {
      options: {
        perPage: 1,
        type: 'fade',
        rewind: true,
        autoplay: true,
        pagination: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        arrows: false,
        interval: 4000,
        speed: 1500,
        width: '100vw',
        height: '100vh'
      }
    }
  },
  computed: {
    ...mapState('api', ['screenSaver']),
    ...mapState('bigScreen', ['phase', 'home', 'inspiration', 'highlight', 'category', 'highlightProduct']),
    ...mapGetters('bigScreen', ['activeIdx']),
    list () {
      const list = []

      if (this.screenSaver.length && this.phase.phase === 'screensaver') {
        this.screenSaver.forEach(item => {
          list.push([item.pic_bottom])
        })
      } else if (this.home.length && this.phase.phase === '00') {
        this.home.forEach(item => {
          const arr = item.type === 'TWOCOL' ? [item.pic_left, item.pic_right] : [item.pic]

          list.push(arr)
        })
      } else if (this.inspiration.length && this.phase.phase === '01') {
        this.inspiration.forEach(item => {
          const arr = item.type === 'TWOCOL' ? [item.pic_left, item.pic_right] : [item.pic]

          list.push(arr)
        })
      } else if (this.highlight.length && this.phase.phase === '02') {
        this.highlight.forEach(item => {
          const arr = item.type === 'TWOCOL' ? [item.pic_left, item.pic_right] : [item.pic]

          list.push(arr)
        })
      } else if (this.category.length && this.phase.phase === 'showroomType') {
        const type = this.category.find(item => item.id === this.phase.current_id).pics
        type.forEach(item => {
          const arr = item.type === 'TWOCOL' ? [item.pic_left, item.pic_right] : [item.pic]

          list.push(arr)
        })
      } else if (this.highlightProduct.length && this.phase.phase === 'highlight') {
        const type = this.highlightProduct.find(item => item.id === this.phase.current_id).pics
        type.forEach(item => {
          const arr = item.type === 'TWOCOL' ? [item.pic_left, item.pic_right] : [item.pic]

          list.push(arr)
        })
      } else if (this.category.length && this.phase.phase === 'showroom') {
        const showrooms = this.category.find(item => item.id === this.phase.current_id).showrooms
        const type = showrooms.find(item => item.id === this.phase.current_child_id).pics
        type.forEach(item => {
          const arr = item.type === 'TWOCOL' ? [item.pic_left, item.pic_right] : [item.pic]

          list.push(arr)
        })
      }

      return this.phase.phase === 'screensaver' ? list : list.filter((item, idx) => idx % 2 === 1)
    }
  },
  watch: {
    activeIdx: {
      handler () {
        if (this.phase.phase !== 'screensaver') return

        this.$refs.bottomCarousel.go(this.activeIdx)
      }
    }
  },
  async created () {
    await Promise.all([
      this.fetchScreenSaver(),
      this.fetchBigScreen(),
      this.fetchBigScreenStatus()
    ])
  },
  methods: {
    ...mapActions('api', ['fetchScreenSaver', 'fetchBigScreen', 'fetchBigScreenStatus'])
  }
}
</script>

<style lang="scss" scoped>
.slide-img {
  width: 100%;
  height: 100%;
  @include bg-cover;

  &__multiple {
    width: 50%;
  }
}

.bottom-carousel {

  &__logo {
    width: 1.146vw;
    position: absolute;
    bottom: 3.52vh;
    left: 1.5625vw;
    z-index: 99999;
  }
}
</style>
